<template>
  <v-row
    justify="center"
    class="faq-cont"
    :style="{
      backgroundColor: type === 'hiw' ? '#f4f4f4' : '',
      paddingBottom: type === 'hiw' ? '90px' : '',
    }"
    style="position: relative"
  >
    <v-col
      cols="auto"
      class="faq-header"
      style="padding-bottom: 20px"
      :style="{ paddingTop: type === 'hiw' ? '77px' : '96px' }"
    >
      <Texting v-if="type === 'hiw'" type="p4bold MainDark" class="shadow-text"
        >FAQ</Texting
      >
      <Texting
        v-if="type === 'pricing'"
        type="p4bold MainDark"
        class="text-center shadow-text"
        >Need help?<br />
        Look through these Q&As.</Texting
      >
    </v-col>
    <div style="width: 100%"></div>
    <v-col cols="10" sm="6" style="padding-bottom: 0; max-width: 744px">
      <v-expansion-panels
        v-model="panel"
        style="z-index: auto; border-radius: 8px !important"
      >
        <v-expansion-panel
          v-for="(item, i) in getItems"
          :key="item.header"
          class="panel"
          :id="'faq-item-' + i"
          v-bind:class="{ panel_active: i === panel }"
        >
          <v-expansion-panel-header style="padding: 13px 10px 13px 27px">
            <Texting
              type="p3bold"
              :style="{ color: i === panel ? '#9E33E0' : '#24124D' }"
              class="d-inline"
              >{{ item.header }}</Texting
            >
            <template v-slot:actions>
              <Images
                :id="'faq-add-icon-' + i"
                width="48"
                height="48"
                class="faq-plus-icon d-inline"
                v-bind:class="{ active1: i === panel, active2: i !== panel }"
                name="addicon.png"
              />
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="my-content"
            style="padding-left: 3px"
          >
            <div style="border-top: 1px solid #a7a0b8; opacity: 0.4"></div>
            <div style="max-width: 580px; padding-top: 20px">
              <Texting
                type="p2 MainDark"
                style="opacity: 0.9; margin-bottom: 24px !important"
                v-html="item.content"
              ></Texting>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <div style="width: 100%"></div>
    <v-col
      v-if="type === 'pricing'"
      cols="auto"
      style="margin-bottom: 20px; padding-top: 5px"
    >
      <Texting type="p2n"
        ><a
          href="mailto:info@perkfection.com"
          style="text-decoration: none; color: #24124d; opacity: 0.7"
          >info@perkfection.com</a
        ></Texting
      >
    </v-col>
    <div
      v-if="type === 'hiw' && this.windowWidth >= 600"
      class="d-none d-sm-flex"
      style="position: absolute; left: 0px; bottom: 42px"
    >
      <Images
        name="https://res.cloudinary.com/dyf7aejih/image/upload/v1617703057/images/faq_robot_q8slcy.svg"
      />
    </div>
  </v-row>
</template>

<script>
import Texting from "./Texting";
import Images from "./MyImages";
import myServices from "@/services/resource";
export default {
  components: { Images, Texting },
  props: {
    type: String,
    pricingType: String,
  },
  data: () => ({
    panel: null,
    items: [],
    windowWidth: window.innerWidth,
  }),
  computed: {
    getItems: function () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.items =
        this.type === "hiw"
          ? [
              {
                header: "How will this help my campaign?",
                content:
                  "The secret sauce behind most crowdfunding platforms lies in the urgency that it creates among buyers, as they are subconsciously pushed to buy now by the implicit time/quantity constraint. Scarcity increases desirability, as it appears more exclusive, more valuable, and gives those that buy now a sense of uniqueness. Each time your buyers see the stock running low they are more keen to convert, as they don’t want to miss out on these desires.",
              },
              {
                header: "Can’t I just do this by hand?",
                content:
                  "Yes, you can. Though you might consider that on average crowdfunding campaigns last about 43,200 minutes (30 days). Huh, imagine how many times you will need to check it manually? And if you don’t do it often, you run the risk of running out of stock or losing conversions because the stock is too high.",
              },
              {
                header: "Is Perkfection secure?",
                content:
                  "Your security is our priority, and we don’t think anyone but you should have access to your Kickstarter/Indiegogo account. <br><br>  We highly recommend you create new user accounts for your team members or collaborators. You can then easily select restricted privileges on campaign management so that each account can only manage what it is required to.<br><br> The granted access can be revoked at any time. We work with this system successfully on all of our projects, so we do not require your main Kickstarter/Indiegogo account credentials.",
              },
              {
                header:
                  "How much time will it take to launch my campaign on Perkfection?",
                content:
                  "Setting up the account is as fast as you can scroll your socials. You’ll be offered a free rewards/pricing review service for customers (and potential customers) after the setup. Don’t hesitate to contact us through our form to make a quote about your free rewards strategy.",
              },
            ]
          : [
              {
                header: "Can I pay later?",
                content:
                  "You need to pay to activate Perkfection. But we know that every campaign is unique and has its own workflow, so we offer a 3-day free pro trial with full functionality. You can use this to check out different features and experience for yourself how Perkfection can help you, in order to make an informed decision — no credit card required! <br><br>For further information please read our Terms and Conditions. And if you need more assistance in discovering how Perkfection can work for you, please reach out to us at info@perkfection.com.",
              },
              {
                header: "Can I view my invoices on Perkfection?",
                content:
                  "Yes. While Stripe offers a very basic automatic invoice — including the sender and receiver information — we provide an additional in-depth invoice. You can also view your invoices and subscription status by simply signing in to your Perkfection account and going to “My Invoices”. You will be able to see the project name, the period you have subscribed for, the entire amount you have paid, and the payment status. After each payment, you’ll also receive an email with the invoice (you can turn this off for your account in the Settings menu). <br><br>PLEASE NOTE: The “My invoices” section becomes available after making your first payment.",
              },
              {
                header: "What kind of perks are shown on Perkfection?",
                content:
                  "Perkfection only works with limited perks. If you have unlimited perks, you might want to consider making some of your rewards limited. Scarcity adds exclusiveness and desirability, and it increases interest in viewers which makes them more likely to convert. <br><br>The following perks are not shown on Perkfection’s dashboard: Unlimited perks/rewards, Hidden perks, and Expired perks from Indiegogo.",
              },
              {
                header: "Do I get notified each time my perks are topped up?",
                content:
                  "Yes. You can choose how to receive your notifications. By default we send you notifications to your email. But you can also choose to receive a summary of your perks’ status at the end of the day, or you can completely turn notifications off.",
              },
            ];
      return this.items;
    },
  },
  watch: {
    panel(val) {
      if (val !== undefined)
        if (this.type === "pricing")
          myServices.myAnalyticsEvents(this, "Pricing", `FAQ-${val + 1} click`);
      if (this.type === "hiw")
        myServices.myAnalyticsEvents(
          this,
          "How it works",
          `FAQ-${val + 1} click`,
        );
    },
    pricingType: function () {
      this.panel = null;
    },
  },
  methods: {
    close() {
      this.items.forEach((item) => {
        if (item.active) {
          setTimeout(function () {
            item.active = false;
          }, 200);
          // _.delay(function () {
          //   item.active = false;
          // }, 300);
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
Texting {
  padding-top: 16px;
}
.panel {
  margin-bottom: 20px;
  box-shadow: 0 8px 14px rgba(36, 18, 77, 0.08), 0 2px 4px rgba(36, 18, 77, 0.1),
    0 1px 2px rgba(36, 18, 77, 0.15);
  min-width: 280px;
  display: grid;
  border-radius: 8px;
}
@media screen and (max-width: 599px) {
  .faq-cont {
    background-color: #f9f9f9 !important;
    padding-bottom: 48px !important;
  }
  .p3bold {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
  }
  .faq-header {
    padding-top: 63px !important;
    padding-bottom: 15px !important;
  }
  .faq-plus-icon {
    max-width: 48px;
  }
  .panel {
    margin-bottom: 16px;
  }
}
</style>
